import { InformationsComplmentaire } from './../../../../entity/Opportunity/InformationsComplmentaire';
import { InformationsSpecifique } from './../../../../entity/Opportunity/InformationsSpecifique';
import { FormlyJsonschema } from '@ngx-formly/core/json-schema';
import { tap } from 'rxjs/operators';
import { AffaireDetails } from 'src/app/entity/Affaires/AffaireDetails';
import { ApiAffairesService } from 'src/app/services/ApiAffaires/api-affaires.service';
import { FormlyFormOptions } from '@ngx-formly/core';
import { FormGroup } from '@angular/forms';
import { ApiOpportunitService } from './../../../../services/ApiOpportunite/api-opportunite.service';
import { Component, Input, OnInit, Output, EventEmitter, HostListener, OnDestroy } from '@angular/core';
import swal from 'sweetalert2';

import { Subscription } from 'rxjs';
import * as Survey from 'survey-angular';
import { log } from 'console';

@Component({
  selector: 'app-information-avancees',
  templateUrl: './information-avancees.component.html',
  styleUrls: ['./information-avancees.component.css'],
})
export class InformationAvanceesComponent implements OnInit, OnDestroy {
  form = new FormGroup({});
  modelspecif: any = {};
  modelcomp: any = {};
  ListeofUnsbscribeservice: Subscription[] = [];
  options: FormlyFormOptions = {};
  fieldscomp = [];
  fieldsspecif = [];
  formdatacomp = new FormGroup({});
  formdataspecif = new FormGroup({});

  formComplementaire = new FormGroup({});
  formSpecifique = new FormGroup({});

  loading: boolean = false;
  @Input() informationsContrat: AffaireDetails;
  @Input() affaire_id: string = '';
  @Input() id_opportunite: string = '';
  infoComplementaire: InformationsComplmentaire;
  infoSpecifique: InformationsSpecifique;
  @Output() infoSpecifiqueexport: EventEmitter<any> = new EventEmitter();
  @Output() infoComplementaireexport: EventEmitter<any> = new EventEmitter();
  @Input() allowed_to_update_affaire: boolean;
  @Output() dataForm: EventEmitter<any> = new EventEmitter();
  specifique = false;
  complementaire = false;

  @Output() onUpdateinformationsSpecifique: EventEmitter<InformationsSpecifique> = new EventEmitter();
  @Output() emitForminformationsSpecifique: EventEmitter<any> = new EventEmitter();

  constructor(
    private apiOpportunitService: ApiOpportunitService,
    private apiAffairesService: ApiAffairesService,
    private formlyJsonschema: FormlyJsonschema
  ) {}

  ngOnInit(): void {
    this.getInformationsSpecifique();
  }

  getInfoSpec() {
    // if (this.specifique == false) {

    this.getInformationsSpecifique();
    this.specifique = true;
    this.complementaire = false;

    // }
  }

  getInfoComp() {
    // if (this.specifique == true) {

    this.getInformationComplementaire();
    this.complementaire = true;
    this.specifique = false;

    // }
  }

  getInformationsSpecifique() {
    this.loading = true;
    if (this.affaire_id !== '' && this.affaire_id !== null && this.apiOpportunitService.formdataaffairspecif === null) {
      const unsubscribegetInfoSpecifiqueAffaireGet = this.apiAffairesService
        .getInfoSpecifiqueAffaireGet(this.affaire_id)
        .subscribe((response) => {
          this.surveyJsInit(response.response.form, response.response.data);

          this.loading = false;
        });
      this.ListeofUnsbscribeservice.push(unsubscribegetInfoSpecifiqueAffaireGet);
    }
  }

  ngOnDestroy(): void {
    this.pushInfo();
    this.ListeofUnsbscribeservice.forEach((element) => {
      element?.unsubscribe();
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunload($event: Event): void {
    this.pushInfo();
  }

  pushInfo() {
    this.submitInfoSpecifique();
  }

  getInformationComplementaire() {
    this.loading = true;
    if (
      this.id_opportunite !== '' &&
      this.id_opportunite !== null &&
      this.apiOpportunitService.formdataaffaircomp === null
    ) {
      const unsubscribegetInfoComplementairesOpportuniteGet = this.apiOpportunitService
        .getInfoComplementairesOpportuniteGet(this.id_opportunite)
        .pipe(
          tap(({ form }) => {
            this.form = new FormGroup({});
            this.formdatacomp = form;
            this.options = {};
            if (form.schema) {
              const params: any = form.schema.properties;
              const infoComplementaire: any[] = [];
              form.schema.properties = {};
              this.modelcomp = {};
              params?.forEach((element) => {
                infoComplementaire[element.name] = element;
                if (this.modelcomp[element.name]) this.modelcomp[element.name] = element.default;
              });
              form.schema.properties = infoComplementaire;
              this.fieldscomp = [this.formlyJsonschema.toFieldConfig(form.schema)];
              const infoCompl = new InformationsComplmentaire();
              infoCompl.id_opp = this.id_opportunite;
              infoCompl.jsonform = form.data;
              infoCompl.infcamp = [];
              this.infoComplementaireexport.emit(infoCompl);
            }
            this.modelcomp = form.data;
          })
        )
        .subscribe((data) => {
          this.loading = false;
        });
      this.ListeofUnsbscribeservice.push(unsubscribegetInfoComplementairesOpportuniteGet);
    } else if (this.apiOpportunitService.formdataaffaircomp?.schema !== undefined) {
      this.formdatacomp = this.apiOpportunitService.formdataaffaircomp;
      if (this.formdatacomp !== null) {
        this.form = new FormGroup({});

        this.fieldscomp = [this.formlyJsonschema.toFieldConfig(this.apiOpportunitService.formdataaffaircomp.schema)];
        this.modelcomp = this.apiOpportunitService.formdataaffaircomp.data;
      }
      this.loading = false;
    }
  }

  surveyJsInit(surveyJSON: any, data: any): void {
    this.loading = true;
    try {
      const survey: Survey.Model = new Survey.Model(surveyJSON);
      survey.data = JSON.parse(data);
      /* const customTheme: any = {
        themeName: 'flat',
        colorPalette: 'light',
        isPanelless: false,
        backgroundImage: '',
        backgroundOpacity: 1,
        backgroundImageAttachment: 'scroll',
        backgroundImageFit: 'cover',
        cssVariables: {
          '--sjs-font-size': '13.6px',
          '--sjs-corner-radius': '5px',
          '--sjs-base-unit': '8px',
          '--sjs-shadow-small': '0px 0px 0px 1px rgba(0, 0, 0, 0.15)',
          '--sjs-shadow-inner': '0px 0px 0px 1px rgba(0, 0, 0, 0.12)',
          '--sjs-border-default': 'none',
          '--sjs-border-light': 'none',
          '--sjs-general-backcolor': 'white',
          '--sjs-general-backcolor-dark': 'rgba(235, 235, 235, 1)',
          '--sjs-general-backcolor-dim-light': 'rgba(255, 255, 255, 1)',
          '--sjs-general-backcolor-dim-dark': 'rgba(235, 235, 235, 1)',
          '--sjs-general-forecolor': 'rgba(0, 0, 0, 0.91)',
          '--sjs-general-forecolor-light': 'rgba(0, 0, 0, 0.45)',
          '--sjs-general-dim-forecolor': 'rgba(0, 0, 0, 0.91)',
          '--sjs-general-dim-forecolor-light': 'rgba(0, 0, 0, 0.45)',
          '--sjs-secondary-backcolor': 'rgba(255, 152, 20, 1)',
          '--sjs-secondary-backcolor-light': 'rgba(255, 152, 20, 0.1)',
          '--sjs-secondary-backcolor-semi-light': 'rgba(255, 152, 20, 0.25)',
          '--sjs-secondary-forecolor': 'rgba(255, 255, 255, 1)',
          '--sjs-secondary-forecolor-light': 'rgba(255, 255, 255, 0.25)',
          '--sjs-shadow-small-reset': '0px 0px 0px 0px rgba(0, 0, 0, 0.15)',
          '--sjs-shadow-medium': '0px 0px 0px 1px rgba(0, 0, 0, 0.1),0px 2px 6px 0px rgba(0, 0, 0, 0.1)',
          '--sjs-shadow-large': '0px 8px 16px 0px rgba(0, 0, 0, 0.1)',
          '--sjs-shadow-inner-reset': '0px 0px 0px 0px rgba(0, 0, 0, 0.12)',
          '--sjs-border-inside': 'none',
          '--sjs-special-red-forecolor': 'rgba(255, 255, 255, 1)',
          '--sjs-special-green': 'rgba(25, 179, 148, 1)',
          '--sjs-special-green-light': 'rgba(25, 179, 148, 0.1)',
          '--sjs-special-green-forecolor': 'rgba(255, 255, 255, 1)',
          '--sjs-special-blue': 'rgba(67, 127, 217, 1)',
          '--sjs-special-blue-light': 'rgba(67, 127, 217, 0.1)',
          '--sjs-special-blue-forecolor': 'rgba(255, 255, 255, 1)',
          '--sjs-special-yellow': 'rgba(255, 152, 20, 1)',
          '--sjs-special-yellow-light': 'rgba(255, 152, 20, 0.1)',
          '--sjs-special-yellow-forecolor': 'rgba(255, 255, 255, 1)',
          '--sjs-article-font-xx-large-textDecoration': 'none',
          '--sjs-article-font-xx-large-fontWeight': '700',
          '--sjs-article-font-xx-large-fontStyle': 'normal',
          '--sjs-article-font-xx-large-fontStretch': 'normal',
          '--sjs-article-font-xx-large-letterSpacing': '0',
          '--sjs-article-font-xx-large-lineHeight': '64px',
          '--sjs-article-font-xx-large-paragraphIndent': '0px',
          '--sjs-article-font-xx-large-textCase': 'none',
          '--sjs-article-font-x-large-textDecoration': 'none',
          '--sjs-article-font-x-large-fontWeight': '700',
          '--sjs-article-font-x-large-fontStyle': 'normal',
          '--sjs-article-font-x-large-fontStretch': 'normal',
          '--sjs-article-font-x-large-letterSpacing': '0',
          '--sjs-article-font-x-large-lineHeight': '56px',
          '--sjs-article-font-x-large-paragraphIndent': '0px',
          '--sjs-article-font-x-large-textCase': 'none',
          '--sjs-article-font-large-textDecoration': 'none',
          '--sjs-article-font-large-fontWeight': '700',
          '--sjs-article-font-large-fontStyle': 'normal',
          '--sjs-article-font-large-fontStretch': 'normal',
          '--sjs-article-font-large-letterSpacing': '0',
          '--sjs-article-font-large-lineHeight': '40px',
          '--sjs-article-font-large-paragraphIndent': '0px',
          '--sjs-article-font-large-textCase': 'none',
          '--sjs-article-font-medium-textDecoration': 'none',
          '--sjs-article-font-medium-fontWeight': '700',
          '--sjs-article-font-medium-fontStyle': 'normal',
          '--sjs-article-font-medium-fontStretch': 'normal',
          '--sjs-article-font-medium-letterSpacing': '0',
          '--sjs-article-font-medium-lineHeight': '32px',
          '--sjs-article-font-medium-paragraphIndent': '0px',
          '--sjs-article-font-medium-textCase': 'none',
          '--sjs-article-font-default-textDecoration': 'none',
          '--sjs-article-font-default-fontWeight': '400',
          '--sjs-article-font-default-fontStyle': 'normal',
          '--sjs-article-font-default-fontStretch': 'normal',
          '--sjs-article-font-default-letterSpacing': '0',
          '--sjs-article-font-default-lineHeight': '28px',
          '--sjs-article-font-default-paragraphIndent': '0px',
          '--sjs-article-font-default-textCase': 'none',
          '--sjs-general-backcolor-dim': 'rgba(0, 0, 255, 0)',
          '--sjs-primary-backcolor': '#21A8A8',
          '--sjs-primary-backcolor-dark': 'rgba(30, 153, 153, 1)',
          '--sjs-primary-backcolor-light': 'rgba(33, 168, 168, 0.1)',
          '--sjs-primary-forecolor': 'rgba(255, 255, 255, 1)',
          '--sjs-primary-forecolor-light': 'rgba(255, 255, 255, 0.25)',
          '--sjs-special-red': 'rgba(229, 10, 62, 1)',
          '--sjs-special-red-light': 'rgba(229, 10, 62, 0.1)',
        },
        headerView: 'basic',
      }; */
      /* console.log(this.informationsContrat?.contrat?.etat_dossier_id);

      if (this.informationsContrat?.contrat?.etat_dossier_id === '7ce3284b743aefde80ffd9aec500e085') {
        const page = survey.getPageByName('last');
      }

      survey.onCurrentPageChanged.add((sender: Survey.SurveyModel) => {
        const surveyData = sender.data;
        const template = sender.getPageByName('last').toJSON();

        // Sauvegarder les données modifiées
        this.dataForm.emit(surveyData);
      });

      survey.onValueChanged.add((sender: Survey.SurveyModel, options: any) => {
        console.log('Nouveau panel ajouté:', options.panel.name);
        const updatedSchema = sender.toJSON(); // Utiliser toJSON pour obtenir le schéma mis à jour
        console.log('Schéma JSON mis à jour après ajout de panel:', updatedSchema);
      }); */
      survey.pages.forEach((page) => {
        page.questions.forEach((question) => {
          question.readOnly = true; // Rendre la question non modifiable
        });
      });
      Survey.SurveyNG.render('surveyElement', { model: survey });

      this.loading = false;
    } catch (error) {
      this.loading = false;
    }
  }

  alertSuccess(data) {
    swal.fire({
      title: 'Opération Réussie!',
      text: data,
      icon: 'success',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#68a45b',
    });
  }

  alertWarning(data) {
    swal.fire({
      title: data,
      text: 'Il faut remplir tout les champs!',
      icon: 'warning',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#e75e5e',
    });
  }

  alertError(data) {
    swal.fire({
      title: data,
      text: "Quelque chose s'est mal passé!",
      icon: 'error',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#d53a3a',
    });
  }

  submitInfoSpecifique() {
    this.infoSpecifique = new InformationsSpecifique();
    this.infoSpecifique.id_opp = this.id_opportunite;
    this.infoSpecifique.json_prod = this.modelspecif;
    this.infoSpecifique.inf_prod = [];
    this.onUpdateinformationsSpecifique.emit(this.infoSpecifique);
    this.emitForminformationsSpecifique.emit(this.formSpecifique);

    this.apiOpportunitService.formdataaffairspecif = this.formdataspecif;
  }
}
