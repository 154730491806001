<div class="row">
    <div class=" position-fixed " style="z-index: 5;">
        <app-filter-affaire-global [collapseFromParent]="collapseFromParent"
            (collapseCloseToparent)="getCollapseValue($event)" (onSubmitFilter)="getDataValue($event)"
            (onPushFilters)="EmitFilterAffaire($event)" [filterSsearch]="filterSsearch">
        </app-filter-affaire-global>
    </div>
</div>
<div class="row " #myDiv>
    <!-- Datatables -->
    <div class="col-lg-12">
        <div class="card mb-4 border-0" style="border-radius: 13px;">

            <div class="row d-flex align-items-center">
              <div class="col-2 ">
             <!--        <button type="button" class="btn-dt-add  ml-4" (click)="AddAffaire()"
                        data-title="Ajouter une affaire">
                        <i class="fa-solid fa-plus icon-btn-add fa-lg"></i>
                    </button> -->
                </div>
                <div class="col-7 blocDate mt-2">
                    <div class="row d-flex justify-content-center align-items-center">
                        <div class="col-1">
                            <img src="assets/icons/calendarListing.svg" class="mr-3" alt="">
                        </div>
                        <div class="col-3">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label> {{'languages.listing.typeDate' | translate}}</mat-label>
                                <mat-select [(value)]="selected" (change)="quickFilterChange()">
                                    <mat-option value="Creation">
                                       {{'languages.opportunite.label_creationdate' | translate}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-6">

                            <mat-form-field appearance="fill" class="w-100 ">
                                <mat-date-range-input [rangePicker]="Effet">
                                    <input [(ngModel)]="date_debut" readonly matStartDate placeholder="Date début"
                                        (click)="openDateeffet()" />
                                    <input [(ngModel)]="date_fin" readonly matEndDate placeholder="date fin"
                                        (click)="openDateeffet()" />

                                </mat-date-range-input>
                                <mat-datepicker-toggle class="m-0 p-0" matPrefix [for]="Effet"> <mat-icon
                                        matDatepickerToggleIcon>
                                        <img src="assets/icons/calendar.svg">
                                    </mat-icon></mat-datepicker-toggle>
                                <img class="remouveDate" matSuffix (click)="clearDateeffet()"
                                    src="assets/icons/delete-left.svg">
                                <mat-date-range-picker [calendarHeaderComponent]="rangeDateEffet"
                                    #Effet></mat-date-range-picker>


                            </mat-form-field>

                        </div>
                        <!-- <div class="col-4">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>{{'languages.listing.label_debutdate' | translate}}</mat-label>
                                <input matInput [matDatepicker]="pickerDebut" id="date_debut" name="date_debut" [(ngModel)]="date_debut" [value]="date_debut">
                                <mat-datepicker-toggle matSuffix [for]="pickerDebut"></mat-datepicker-toggle>
                                <mat-datepicker #pickerDebut></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-4">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>{{'languages.listing.label_enddate' | translate}} </mat-label>
                                <input matInput [matDatepicker]="pickerFin" id="date_fin" name="date_fin" [(ngModel)]="date_fin" [value]="date_fin">
                                <mat-datepicker-toggle matSuffix [for]="pickerFin"></mat-datepicker-toggle>
                                <mat-datepicker #pickerFin></mat-datepicker>
                            </mat-form-field>
                        </div> -->
                        <div class="col-2 w-100 pl-0 d-flex justify-content-center">
                            <button type="button" class="filtrer" (click)="getDataValue('')">
                             <img src="assets/icons/filtre.svg" class="mr-3" alt="">
                             {{'languages.buttons.filtrer' | translate}}
                            </button>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-3 mt-2 p-2 d-flex justify-content-center">
                    <button class="filtreFavoris">
                        <img class="mr-3" src="assets/icons/filtreFavoris.svg">
                        {{'languages.buttons.rechercheFavories' | translate}}
                    </button>
                </div> -->
                <div class="col-3 p-2  text-right">
                    <input class="search" name="" [(ngModel)]="sSearch"  (input)="onInputChange($event.target.value)" placeholder="{{'languages.miniListing.search' | translate}}">
                    <i class=" fa-solid fa-search icon-search"></i>
                </div>

            </div>

            <div class="row mt-3" >

                <button type="button" *ngIf="actionGroupeAccess" class="btn-dt-actions-groupee ml-4" (click)="updateAffaire()" [disabled]="!actionButton"
                 >
                 {{'languages.groupedactions.groupedactions' | translate}}
                  </button>
                </div>

            <div class="table-responsive p-3">
                <table class="table align-items-center table-flush" id="dataTable1">
                    <thead class="head-table">
                        <tr>
                            <th>
                                <input type="checkbox" [(ngModel)]="masterSelected" name="list_name" value="m1"
                                    (change)="checkUncheckAll()" />
                            </th>
                            <th >{{'languages.listing.actions' | translate}}
                            </th>
                            <th   (click)="setSortField('affaire_date_creation')">{{'languages.opportunite.label_creation' | translate}}
                                <div class="d-inline-block" [ngClass]="{'sort-up': sort_type == 'asc' && sort_field=='affaire_date_creation','sort-down': sort_type == 'desc' && sort_field == 'affaire_date_creation', 'sort-active': sort_field=='affaire_date_creation'}">
                                    <i class="fa-solid fa-arrow-down-short-wide fa-lg ml-2"></i>

                                </div>
                            </th>
                            <th (click)="setSortField('prospects_name')" >{{'languages.affaire.label_client' | translate}}
                                <div class="d-inline-block" [ngClass]="{'sort-up': sort_type == 'asc' && sort_field=='prospects_name','sort-down': sort_type == 'desc' && sort_field == 'prospects_name', 'sort-active': sort_field=='prospects_name'}">
                                    <i class="fa-solid fa-arrow-down-short-wide fa-lg ml-2"></i>

                                </div>
                            </th>

                            <th (click)="setSortField('prospects_name')" >{{'languages.opportunite.label_product' | translate}}
                              <div class="d-inline-block" [ngClass]="{'sort-up': sort_type == 'asc' && sort_field=='prospects_name','sort-down': sort_type == 'desc' && sort_field == 'prospects_name', 'sort-active': sort_field=='prospects_name'}">
                                  <i class="fa-solid fa-arrow-down-short-wide fa-lg ml-2"></i>

                              </div>
                          </th>

                              <th (click)="setSortField('affaire_produit_contrat')" > Affectée à
                                <div class="d-inline-block" [ngClass]="{'sort-up': sort_type == 'asc' && sort_field=='affaire_produit_contrat','sort-down': sort_type == 'desc' && sort_field == 'affaire_produit_contrat', 'sort-active': sort_field=='affaire_produit_contrat'}">
                                    <i class="fa-solid fa-arrow-down-short-wide fa-lg ml-2"></i>

                                </div>
                            </th>

                            <th (click)="setSortField('etat')" >
                                {{'languages.opportunite.label_status' | translate}}
                                <div class="d-inline-block" [ngClass]="{'sort-up': sort_type == 'asc' && sort_field=='etat','sort-down': sort_type == 'desc' && sort_field == 'etat', 'sort-active': sort_field=='etat'}">
                                    <i class="fa-solid fa-arrow-down-short-wide fa-lg ml-2"></i>

                                </div>
                            </th>
                            <th>{{'languages.affaire.Motifs' | translate}}</th>
                        </tr>
                    </thead>
                    <tbody class="body-table">
                        <tr *ngFor="let affaires of affairesList.aaData; let i = index">
                            <td>
                                <div class="mt-2">
                                    <label class="container">
                                        <input type="checkbox" class="check" [(ngModel)]="affaires.isSelected"
                                            name="list_name" value="{{affaires.id}}" (change)="isAllSelected()">
                                    </label>

                                </div>
                            </td>
                            <td>
                                <div class="d-flex justify-content-center align-items-center">
                                    <!-- <div class="pt-1">
                                        <button type="button" class="btn-dt-list" data-toggle="modal" data-target="#ActionsPhone" (click)="getPhoneList(affaires.id_prospect)">
                                                <img src="/assets/icons/phone-icon.svg">
                                            </button>
                                    </div> -->
                                    <div class="">
                                        <a type="button" class="btn-dt-list" (click)=" goToEdit()"
                                            routerLink="/affaires/details/{{affaires.affaire_id}}/">
                                            <img src="/assets/icons/edit-icon.svg">
                                        </a>
                                    </div>
                                    <div>
                                        <input type='checkbox' id="permanent_{{i}}" title="Add Favoris" class="star"
                                            [checked]="affaires.add_to_fav" />
                                        <label for="permanent_{{i}}"
                                            (click)="add_suivi_affaire(affaires.affaire_id)"></label>
                                    </div>
                                </div>
                            </td>
                            <td>{{affaires.affaire_date_creation | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
                            <td>
                              <ng-container *ngIf="affaires.reason_social">{{affaires.reason_social}}</ng-container>
                              <ng-container *ngIf="affaires.nom_user || affaires.prenom_user">
                                {{ affaires.nom_user + " " + affaires.prenom_user }}
                              </ng-container>
                            </td>
                            <td>
                                {{affaires.contrat_type}}
                            </td>
                            <td>
                              <ng-container *ngIf="affaires.nom_affected || affaires.prenom_affected; else notYetAffected">
                                {{affaires.nom_affected + " " + affaires.prenom_affected }}
                              </ng-container>
                              <ng-template #notYetAffected><small class="text-secondary">Pas encore affectée</small></ng-template>
                            </td>


                            <td>
                                <div class="btn-status d-flex justify-content-center"
                                    [style.background]="affaires.etat_background" [style.color]="affaires.etat_color">
                                    {{affaires.etat}}</div>
                            </td>

                            <td *ngIf="affaires.libele_motif == null">-</td>
                            <td *ngIf="affaires.libele_motif !==null">
                                <div class="pb-1" id="tagsList">
                                    <mat-chip-set class="example-chip ">
                                        <mat-chip class="example-box  mt-2"
                                            [ngStyle]="{'background-color':affaires.background_motif, 'color':affaires.color_motif}">
                                            {{affaires.libele_motif}}
                                        </mat-chip>
                                    </mat-chip-set>
                                </div>


                            </td>
                        </tr>

                    </tbody>

                </table>
                <div class="row d-flex justify-center align-items-center text-center ">
                    <p *ngIf="affairesList.aaData?.length===0"  class="col-12 noAffaire"> {{'languages.affaire.pas_daffaire' | translate}}  </p>
                   </div>

            </div>
        </div>
        <div class="navbar-center row mb-4">
            <div class="col pt-1">
                <div class="row w-50 flex-nowrap">
                    <div class="col mt-1">
                        <p>{{'languages.miniListing.Afficher' | translate}}</p>
                    </div>
                    <div class="col mx-1">
                        <select [(ngModel)]="limit" (change)="updateListAffaires()"
                            class="bg-white border pt-1 rounded font-weight-bold">
                            <option value="15">15</option>
                            <option value="25">25</option>
                            <option value="30">30</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                    <div class="col mt-1">
                        <p class="text-nowrap"> {{'languages.miniListing.pagesPerPage' | translate}} {{totalLength}}  {{'languages.miniListing.element' | translate}} </p>
                    </div>
                </div>
            </div>
            <div class="col-8">
                <nav aria-label="Page navigation example" *ngIf="totalPages>0">
                    <ul class="pagination pg-blue">
                        <li (click)="first()" class="page-item" [ngClass]="{'disabled':isFirst()}">
                            <a class="page-link-symbol">
                                <span aria-hidden="true">&laquo;</span>
                            </a>
                        </li>
                        <li *ngIf="!isFirst()" (click)="prev()" class="page-item mx-2">
                            <a class="page-link-symbol-chevron" aria-label="Previous">
                                <i class="fa fa-chevron-left "></i>
                            </a>
                        </li>
                        <ng-container *ngFor="let item of [].constructor(3); let i = index">
                            <li *ngIf="i+page-3>0" class="page-item">
                                <a (click)="changepage(i+page-3)" class="page-link mt-2 border rounded">{{i + page -
                                    3}}</a>
                            </li>
                        </ng-container>
                        <ng-container *ngFor="let item of [].constructor(3); let i = index">
                            <li *ngIf="i+page<totalPages" class="page-item">
                                <a (click)="changepage(i+page)" class="page-link mt-2 border rounded"
                                    [ngClass]="{'active-page':page==(i+page)}">
                                    {{i + page}}
                                </a>
                            </li>
                        </ng-container>
                        <li *ngIf="totalPages>1 && totalPages > 3 " class="m-2"> ... </li>
                        <li class="page-item">
                            <a (click)="changepage(totalPages)" class="page-link mt-2 border rounded"
                                [ngClass]="{'active-page':page==totalPages}">{{totalPages}}</a>
                        </li>
                        <li *ngIf="!isLast()" (click)="next()" class="page-item mx-2">
                            <a class="page-link-symbol-chevron" aria-label="Next">
                                <i class="fa fa-chevron-right"></i>
                            </a>
                        </li>
                        <li (click)="last()" class="page-item" [ngClass]="{'disabled':isLast()}">
                            <a class="page-link-symbol">
                                <span aria-hidden="true">&raquo;</span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="col">
                <div class="row position-absolute flex-nowrap" style="right: 0px">
                    <div class="col text-nowrap text-right mt-2">
                        <p>{{'languages.listing.label_goto' | translate}}</p>
                    </div>
                    <div class="col mt-1 ml-2">
                        <input [(ngModel)]="intervale" size="1" min="1" onkeypress="return event.charCode >= 48"
                            (keyup.enter)="getchangeLimite()" (change)="getchangeLimite()" type="number"
                            style="margin-left: 4px;" size="1"
                            class="page_input bg-white border rounded font-weight-bold" [value]="page"
                            max="{{totalPages}}">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--------------------------------Action Phone----------------------------------->
<div class="modal fade" id="ActionsPhone" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog model-lg" role="document">
        <div class="modal-content" style="width: 60rem; margin-left: -135px;">
            <div class="modal-header">
<i class="fa-solid fa-phone-flip fa-2x"></i>
                <h2 style="margin-left: 15px">{{'languages.opportunite.label_phoneactions' | translate}}</h2>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col">
                        <ng-container *ngIf="show_loading_add">
                            <div class="col-12 loader-spinner">
                                <div class="spinner-style">
                                    <mat-spinner [diameter]="30"></mat-spinner>
                                </div>
                            </div>
                        </ng-container>
                        <div class="row" *ngIf="list_tel.mobile!= null && list_tel.mobile!=''">
                            <div class="col-3 text-center">
                                {{'languages.prospect.Mobile' | translate}}
                            </div>
                            <div class="col-6 text-center">
                                {{this.list_tel.mobile}}
                            </div>
                            <div class="col-3 text-center">

                                <button class="btn" (click)="callVoip(list_tel.mobile)">
                                    <i class="fa-solid fa-phone-flip fa-lg"
                                        style="margin-left: 5px; color: #43ac43;"></i>
                                </button>
                            </div>
                        </div>
                        <div class="row" *ngIf="list_tel.tel!= null && list_tel.tel!=''">
                            <div class="col-3 text-center">
                                {{'languages.prospect.phone' | translate}}
                            </div>
                            <div class="col-6 text-center">
                                {{this.list_tel.tel}}
                            </div>
                            <div class="col-3 text-center">

                                <button class="btn" (click)="callVoip(list_tel.tel)">
                                    <i class="fa-solid fa-phone-flip fa-lg"
                                    style="margin-left: 5px; color: #43ac43;"></i>

                                </button>
                            </div>
                        </div>
                        <div class="row" *ngIf="list_tel.tel2!= null && list_tel.tel2!=''">
                            <div class="col-3 text-center">
                                {{'languages.prospect.phone' | translate}} 2
                            </div>
                            <div class="col-6 text-center">
                                {{this.list_tel.tel2}}
                            </div>
                            <div class="col-3 text-center">
                                <button class="btn" (click)="callVoip(list_tel.tel2)">
                                    <i class="fa-solid fa-phone-flip fa-lg"
                                    style="margin-left: 5px; color: #43ac43;"></i>

                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <button class="btn-style" data-dismiss="modal">{{'languages.buttons.fermer' | translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>
